import { Component, DestroyRef, HostListener, inject, OnInit, ViewChild } from '@angular/core';
import { distinctUntilChanged, filter, fromEvent, Observable, startWith, switchMap, take, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IAppEvent } from '@app/shared/types/app-event-interface';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { AppStateService } from '@app/shared/services/app-state.service';
import { SoundService } from '@app/shared/services/sound.service';
import { DELAY } from '@app/shared/constants/delay-enums';
import { LoadingService } from '@app/shared/services/loading.service';
import { AppSettingsService } from '@app/shared/services/app-settings.service';
import { IAppSettings } from '@app/shared/types/app-settings-interface';
import { ActivatedRoute, Params } from '@angular/router';
import { NarratorComponent } from '@app/shared/components/narrator/narrator.component';
import { ActivityRegistryService } from '@app/shared/services/activity-registry.service';
import { IActivityRegistry } from '@app/shared/types/activity-registry.interface';
import { DeviceOrientation, getDeviceOrientation } from '@app/shared/helpers/device-orientation';
import { ConfirmationService } from 'primeng/api';
import { KEY_EVENTS } from '@app/shared/constants/key-event-enums';
import { ActivitySessionService } from '@app/shared/services/activity-session.service';
import { IActivitySessionResume } from '@app/shared/types/activity-session-interface';
import { ACTIVITY_IDS } from '@app/shared/constants/activity-ids';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';

@Component({
  selector: 'app-activity-layout',
  templateUrl: './activity-layout.component.html',
  styleUrls: ['./activity-layout.component.scss'],
  providers: [],
})
export class ActivityLayoutComponent implements OnInit {
  // todowilson: level should come from back end as part of the activity property
  protected keyboardInstructionsExpression = '';
  protected activityBackgroundImg = '';
  protected showActivityOverlay = false;
  protected appEvent$!: Observable<IAppEvent>;

  // todowilson: can be observable
  protected appSettings: IAppSettings;
  protected activityStarted = false;
  protected activityCompleted = false;
  protected isSoundPaused = false;
  protected activity$: Observable<IActivityRegistry>;
  protected activityParams: Params;
  protected activity: IActivityRegistry;
  protected playButtonImage = '';
  protected playButtonCursorImage = '';
  protected isDarkBackground = false;
  protected activityType: string = ACTIVITY_IDS.UnKnown;
  protected showSkipButton: boolean = true;
  protected firstPuzzleCompleted: boolean = false;
  protected firstPuzzleNotCompleted: boolean = false;
  protected recordingsStarted: boolean = false;
  protected recordedExpressions: string[] = [];

  @ViewChild(NarratorComponent) protected _narratorComp: NarratorComponent;

  private _welcomeExpression = '';

  private readonly _destroyRef = inject(DestroyRef);
  private readonly _appStateService = inject(AppStateService);
  private readonly _soundService = inject(SoundService);
  private readonly _activitiesService = inject(ActivityRegistryService);
  private readonly _appSettingsService = inject(AppSettingsService);
  private readonly _loadingService = inject(LoadingService);
  private readonly _route = inject(ActivatedRoute);
  private readonly _dialogConfirmationService = inject(ConfirmationService);
  private readonly _activitySessionService = inject(ActivitySessionService);

  private readonly _minWidth = 1024; // min supported Width
  private readonly _minHeight = 768; // min supported Height

  isSoundPlaying$ = this._soundService.soundIsPlaying$;
  showMicPopup: boolean = false;
  isRecordingEnabled: boolean = false;

  ngOnInit() {
    this.loadActivity();
    this.initAppState();
    this.extractActivityType();

    setTimeout(() => {
      this.prepareActivityStart();
    }, DELAY.S2);

    this.initKeyEvents();

    this.registerDeviceOrientationEvent();

    // based upon level, we show different color buttons
    this.playButtonImage = `/assets/play-button-${this._appStateService.level}.svg`;
    this.playButtonCursorImage = `/assets/cursor-click-black.svg`;

    const self = this;
    window.addEventListener('resize', function () {
      if (this.window.innerWidth < self._minWidth || this.window.innerHeight < self._minHeight) {
        self.handleTooSmall();
      } else {
        self._dialogConfirmationService.close();
      }
    });

    // Listen for the skip introduction event
    this._appStateService.appEvent$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter(event => event.area === APP_EVENT_AREAS.SKIP_INTRODUCTION)
      )
      .subscribe(() => {
        // Check if the activity hasn't started or hasn't been completed
        if (!this.activityCompleted && !this.activityStarted && this.showActivityOverlay) this.completeActivityStart();

        if (!this.activityCompleted && !this.activityStarted && !this.showActivityOverlay) {
          this._soundService.stopExpression();
          this.completeActivityStart();  // Skip directly to the activity start
        }
      });
  }

  loadActivity() {
    this.activity$ = this._route.queryParams.pipe(
      take(1),
      switchMap((params: Params) => {
        this.activityParams = params;
        this._activitiesService.setActivityParams(params['l'], params['u'], params['w'], params['asi']);
        return this._appStateService.activityRegistry$.pipe(
          tap((activity: IActivityRegistry) => {
            // set the background image
            this.activityBackgroundImg = "url('./assets/" + activity.theme.background.image + "')";
            this.isDarkBackground = this.activityBackgroundImg.includes('dark');
            this.loadAppSettings();
            this._loadingService.showLoading();
            // send activity to state for global access
            this._appStateService.appEvent$.next({
              area: APP_EVENT_AREAS.SET_ACTIVITY_REGISTRY,
              activityRegistry: activity,
            });
          })
        );
      })
    );

    // get some data out of the current activity
    this.activity$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((activity: IActivityRegistry) => {
      // play theme background track at lower volume
      this.activity = activity;
      this._welcomeExpression = activity.welcomeExpression;
    });
  }

  initAppState() {
    this.appEvent$ = this._appStateService.appEvent$;
    this.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.ACTIVITY_COMPLETE) {
        this.activityCompleted = event.showActivityComplete || false;
      }
    });
  }

  initKeyEvents() {
    fromEvent(window, 'keyup')
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((event: any) => event.code === 'Enter' || event.code === 'Space')
      )
      .subscribe(() => {
        if (this.showActivityOverlay) {
          this.startActivity();
        }
      });
  }

  loadAppSettings() {
    this._appSettingsService
      .getAppSettings()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((appSettings: IAppSettings) => {
        this.appSettings = appSettings;
        this.keyboardInstructionsExpression = this.appSettings.expressions.keyboardExpression;
      });
  }

  startActivity() {
    this._activitySessionService.getSessionMetrics()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((session: IActivitySessionResume | undefined) => {
        if (session) {
          let puzzleLength = JSON.parse(session.puzzlesJSON).length; 
          const puzzleOneCompleted = puzzleLength > 0;
          if (puzzleOneCompleted) {
            this.firstPuzzleCompleted = true;
          } else {
            this.firstPuzzleNotCompleted = true;
          }

          this.recordingsStarted = JSON.parse(session.recordingsJSON).length > 0;
          if (this.recordingsStarted) this.recordedExpressions = JSON.parse(session.recordingsJSON).map((recording: { expression: string }) => recording.expression);
        }
        const isRecordingAllowed = session && session.hasRecordingPermission && 
          [ACTIVITY_IDS.NotSoTricky, ACTIVITY_IDS.WordFindFun, ACTIVITY_IDS.WorldOfWords].includes(this.activityType as ACTIVITY_IDS);
  
        if (isRecordingAllowed) {
          // Check microphone permissions if recording is enabled
          navigator.permissions.query({ name: 'microphone' as PermissionName })
            .then(permissionStatus => {
              if (permissionStatus.state === 'granted') {
                // Microphone permission is granted
                this.showActivityOverlay = false;
                this.showMicPopup = false;
                this.isRecordingEnabled = true;
                this.passRecordingState();
                this.startActivityLogic();
              } else if (permissionStatus.state === 'denied') {
                // Microphone permission explicitly denied, skip prompt
                this.isRecordingEnabled = false;
                this.passRecordingState();
                this.startActivityLogic();
              } else {
                // Permission not yet granted (prompt state)
                this.showActivityOverlay = false;
                this.showMicPopup = true;
              }
            })
        } else {
          // Recording not allowed, skip to normal activity flow
          this.startActivityLogic();
        }
      });
      setTimeout(() => {
        AnimationHelpers.animate(`skip-button`, ANIMATIONS.Pulse, DELAY.M30);
      }, DELAY.S1);
  }
  
  startActivityLogic(){
    this.showActivityOverlay = false;
    const carouselActivity = [ACTIVITY_IDS.NotSoTricky, ACTIVITY_IDS.WordFindFun].includes(this.activityType as ACTIVITY_IDS);

    if (this.firstPuzzleCompleted) {
      //if session resumed and first puzzle was completed
      this._appStateService.appEvent$.next({
        area: APP_EVENT_AREAS.WELCOME,
      });
      this._appStateService.appEvent$.next({
        area: APP_EVENT_AREAS.START_GAME,
      });
      this._appStateService.appEvent$.next({
        area: APP_EVENT_AREAS.PROFILE_NARRATOR,
      });

      this._appStateService.resumeSession$.next({
        area: APP_EVENT_AREAS.PAUSED_SESSION_RESUMED,
        payload: { puzzleOneCompleted: true, recordedExpressions: this.recordedExpressions},
      });

      this.activityStarted = true;
      this.firstPuzzleCompleted = false;
    } else if (this.firstPuzzleNotCompleted && this.isRecordingEnabled && this.recordingsStarted && carouselActivity) {
      //if session resumed, recording enabled and first puzzle not completed
      this._appStateService.appEvent$.next({
        area: APP_EVENT_AREAS.WELCOME,
      });
      this._appStateService.appEvent$.next({
        area: APP_EVENT_AREAS.START_GAME,
      });
      this._appStateService.appEvent$.next({
        area: APP_EVENT_AREAS.PROFILE_NARRATOR,
      });

      this._appStateService.resumeSession$.next({
        area: APP_EVENT_AREAS.PAUSED_SESSION_RESUMED,
        payload: { puzzleOneCompleted: false, recordedExpressions: this.recordedExpressions},
      });

      this.activityStarted = true;
      this.firstPuzzleNotCompleted = false;
    } else {  
      //regular actvity start
      this._appStateService.appEvent$.next({
        area: APP_EVENT_AREAS.WELCOME,
        welcomeExpression: this._welcomeExpression,
        callback: () => {
          this._appStateService.appEvent$.next({
            area: APP_EVENT_AREAS.START_GAME,
          });
          this._appStateService.appEvent$.next({
            area: APP_EVENT_AREAS.PROFILE_NARRATOR,
          });
          this.activityStarted = true;
        },
      });
    }
  }


  onSpeakerClick() {
    this.isSoundPaused = !this.isSoundPaused;
    this._soundService.pauseExpression();
  }

  private prepareActivityStart() {
    this._loadingService.hideLoading();
    this.showActivityOverlay = true;
    this._appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.CENTER_NARRATOR,
    });
  }

  private registerDeviceOrientationEvent() {
    // listen for device orientation events (triggers once on page load)
    fromEvent(window, 'deviceorientation')
      .pipe(
        distinctUntilChanged(),
        startWith(null),
        tap(() => this.handleOrientation()),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();
  }

  private handleOrientation() {
    const orientation = getDeviceOrientation();
    if (orientation === DeviceOrientation.Portrait) {
      this._dialogConfirmationService.confirm({
        message: 'Portrait mode is currently unsupported. Please rotate your device to landscape mode for the best experience.',
        header: 'Orientation Warning',
        rejectVisible: false,
        acceptLabel: 'Ok',
        accept: () => {
          // logic after dialog is accepted
        },
      });
    } else if (orientation === DeviceOrientation.Landscape) {
      this._dialogConfirmationService.close();
    }
  }

  private handleTooSmall() {
    this._dialogConfirmationService.confirm({
      header: 'Please adjust your browser width and height for the best experience.',
      rejectVisible: false,
      acceptLabel: 'Ok',
    });
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === KEY_EVENTS.SpaceBar || event.key === KEY_EVENTS.Enter) {
      if (!this.showActivityOverlay) return;
      this.startActivity();
    }
  }

  // @HostListener('window:keydown', ['$event'])
  // handleKeyDown(event: KeyboardEvent) {
  //   // play/pause audio
  //   if (event.key === 'p') {
  //     if (!this.isSoundPlaying$) return;
  //     this.onSpeakerClick();
  //   }
  // }

  // Helper method to skip intro and directly go to puzzle
  completeActivityStart() {
    this.activityStarted = true;
    this.showActivityOverlay = false;

    this._appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.START_GAME,
    });
    this._appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PROFILE_NARRATOR,
    });
  }

  onSkipIntroButtonClick() {
    this._soundService.stopExpression();
    this.showSkipButton = false;

    this.completeActivityStart();
    this._appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.JUMP_TO_FIRST_PUZZLE,
    });

  }

  // Handle the decision from the microphone access popup
  onMicAccessDecision(allowed: boolean) {
    this.showMicPopup = false; 
  
    if (allowed) {
      this.requestBrowserMicAccess();
    } else {
      this.isRecordingEnabled = false;  
      this.passRecordingState();  
      this.startActivityLogic(); 
    }
  }
  
  // Request microphone access using the browser's native permission prompt
  requestBrowserMicAccess() {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(() => {
        this.isRecordingEnabled = true; 
        this.passRecordingState();  
        this.startActivityLogic();  
      })
      .catch(() => {
        this.isRecordingEnabled = false;  
        this.passRecordingState(); 
        this.startActivityLogic(); 
      });
  }
    
  // Pass the recording state to AppStateService
  passRecordingState() {
    this._appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.RECORDING_STATE_UPDATE,
      payload: { isRecordingEnabled: this.isRecordingEnabled }
    });
  }

  extractActivityType() {
    this._route.firstChild?.url
    .pipe(takeUntilDestroyed(this._destroyRef))
    .subscribe(urlSegments => {
      if (urlSegments.length > 0) {
        const activitySegment = urlSegments[0].path;

        if (Object.values(ACTIVITY_IDS).includes(activitySegment as ACTIVITY_IDS)) {
          this.activityType = activitySegment as ACTIVITY_IDS;
        } else {
          this.activityType = ACTIVITY_IDS.UnKnown;
        }
      }
    });
  }
}
